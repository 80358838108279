import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/dev-site/dev-site/src/templates/Page/index.ts";
import SandboxIntro from '/content/_sandbox-intro.mdx';
import SandboxLicenseKey from '/content/_sandbox-license-key.mdx';
import SandboxTestData from './_sandbox-geoip-test-data.mdx';
import SandboxLimits from '/content/_sandbox-limits.mdx';
import SandboxLearnMore from '/content/_sandbox-learn-more.mdx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <SandboxIntro mdxType="SandboxIntro" />
    <div {...{
      "id": "toc-generate-a-license-key-for-the-sandbox"
    }}><h2 {...{
        "id": "generate-a-license-key-for-the-sandbox",
        "parentName": "div"
      }}>{`Generate a License Key for the Sandbox`}</h2>
      <SandboxLicenseKey mdxType="SandboxLicenseKey" /></div>
    <div {...{
      "id": "toc-build-your-integration"
    }}><h2 {...{
        "id": "build-your-integration",
        "parentName": "div"
      }}>{`Build your integration`}</h2>
      <p {...{
        "parentName": "div"
      }}>{`Consult our `}
        <a {...{
          "href": "/geoip/geolocate-an-ip/web-services",
          "parentName": "p"
        }}>{`quick start guide`}</a>
        {` or our
`}
        <a {...{
          "href": "/geoip/geolocate-an-ip/web-services#client-apis",
          "parentName": "p"
        }}>{`list of client APIs`}</a>
        {` to
develop your integration.`}</p></div>
    <div {...{
      "id": "toc-point-your-integration-to-the-sandbox-service-endpoints"
    }}><h2 {...{
        "id": "point-your-integration-to-the-sandbox-service-endpoints",
        "parentName": "div"
      }}>{`Point your integration to the Sandbox service endpoints`}</h2>
      <p {...{
        "parentName": "div"
      }}>{`Use the service endpoints for the Sandbox version of the web services, as listed
in the table below:`}</p>
      <table {...{
        "parentName": "div"
      }}>

        <thead {...{
          "parentName": "table"
        }}>

          <tr {...{
            "parentName": "thead"
          }}>

            <th {...{
              "parentName": "tr"
            }}>{`Sandbox Service`}</th>


            <th {...{
              "parentName": "tr"
            }}>{`HTTP Method`}</th>


            <th {...{
              "parentName": "tr"
            }}>{`Endpoint`}</th>

          </tr>

        </thead>


        <tbody {...{
          "parentName": "table"
        }}>

          <tr {...{
            "parentName": "tbody"
          }}>

            <td {...{
              "parentName": "tr"
            }}>{`Country`}</td>


            <td {...{
              "parentName": "tr"
            }}><inlineCode {...{
                "parentName": "td"
              }}>{`GET`}</inlineCode></td>


            <td {...{
              "parentName": "tr"
            }}><inlineCode {...{
                "parentName": "td"
              }}>{`https://sandbox.maxmind.com/geoip/v2.1/country/{ip_address}`}</inlineCode></td>

          </tr>


          <tr {...{
            "parentName": "tbody"
          }}>

            <td {...{
              "parentName": "tr"
            }}>{`City Plus`}</td>


            <td {...{
              "parentName": "tr"
            }}><inlineCode {...{
                "parentName": "td"
              }}>{`GET`}</inlineCode></td>


            <td {...{
              "parentName": "tr"
            }}><inlineCode {...{
                "parentName": "td"
              }}>{`https://sandbox.maxmind.com/geoip/v2.1/city/{ip_address}`}</inlineCode></td>

          </tr>


          <tr {...{
            "parentName": "tbody"
          }}>

            <td {...{
              "parentName": "tr"
            }}>{`Insights`}</td>


            <td {...{
              "parentName": "tr"
            }}><inlineCode {...{
                "parentName": "td"
              }}>{`GET`}</inlineCode></td>


            <td {...{
              "parentName": "tr"
            }}><inlineCode {...{
                "parentName": "td"
              }}>{`https://sandbox.maxmind.com/geoip/v2.1/insights/{ip_address}`}</inlineCode></td>

          </tr>

        </tbody>

      </table>
      <p {...{
        "parentName": "div"
      }}>{`Remember to use the license key generated by your Sandbox account to
authenticate your requests.`}</p></div>
    <div {...{
      "id": "toc-test-data-for-the-geoip-sandbox"
    }}><h2 {...{
        "id": "test-data-for-the-geoip-sandbox",
        "parentName": "div"
      }}>{`Test Data for the GeoIP Sandbox`}</h2>
      <SandboxTestData mdxType="SandboxTestData" />
      <SandboxLimits mdxType="SandboxLimits" /></div>
    <div {...{
      "id": "toc-learn-more-about-the-maxmind-sandbox"
    }}><h2 {...{
        "id": "learn-more-about-the-maxmind-sandbox",
        "parentName": "div"
      }}>{`Learn more about the MaxMind Sandbox`}</h2>
      <SandboxLearnMore mdxType="SandboxLearnMore" /></div>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      